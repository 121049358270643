<template>
    <BaseModal
        :modalStyleList="modalStyles"
        @close="onClose"
    >
        <template v-slot:modalBody>
            <div class="template">
                <div class="template__title">
                    Копировать настройки
                </div>
                <p class="template__text">Выберите шаблон для копирования настроек</p>
                <div class="template__content">
                    <BaseSelect
                        :options="templateList"
                        :value="selectedId"
                        :canSearch="true"
                        :isVirtualSearch="false"
                        :position="'left'"
                        :style="{width: '250px'}"
                        :previewColor="'#ffffff'"
                        defaultTitle="Выберите шаблон"
                        class="cloud-import-edit-setting__select"
                        @check="onSelect"
                        @lazyload="onLazyload"
                        @onSearchValue="onSearch"
                        @onToggleDropdown="onOpenList"
                    />
                </div>
                <div
                    class="template__content-options"
                    v-if="isSelectedTemplate"
                >
                    <div>
                        <h3>№ листа</h3>
                        <BaseInput
                            class="sheet-input"
                            :value="parentSheet"
                            :theme="'filter'"
                            :type="'number'"
                            @onInput="onInputParentSheet"
                        />
                    </div>
                </div>
                <div class="template__button-container">
                    <div class="template__button-container__right">
                        <BaseButton
                            :disabled="isDisabledButton"
                            view="secondary"
                            @click="onCopy"
                        >
                            Копировать
                        </BaseButton>
                    </div>
                </div>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from "../Base/BaseModal.vue";
import BaseSelect from "@/components/Base/BaseSelect";
import BaseButton from "@/components/Base/BaseButton";
import ServiceCloudImport from "@/services/ServicesCloudImport/ServiceCloudImport";
import BaseInput from "../Base/BaseInput.vue";

export default {
    name: "CopyTemplateModal",
    components: {
        BaseModal,
        BaseSelect,
        BaseButton,
        BaseInput
    },
    /**
     * Локальные данные
     */
    data() {
        return {
            modalStyles: {marginTop: '5%'},
            templateList: [],
            selectedId: null,
            page: 0,
            searchQuery: "",
            pageCount: 0,
            parentSheet: null
        };
    },

    computed: {
        searchParams() {
            return {
                name: this.searchQuery,
                type: 'FOLDER'
            }
        },

        isDisabledButton() {
            return !this.isSelectedTemplate;
        },

        isSelectedTemplate() {
            return !!this.selectedId
        }
    },

    watch: {
        page() {
            if(this.page === 0){
                this.resetList()
            } else {
                this.getPage()
            }
        }
    },
    methods: {
        onClose() {
            this.$emit("onCloseCopyTemplateModal");
        },

        onSelect(value) {
            const {id = null} = value;
            this.selectedId = id;
        },

        onLazyload() {
            if(this.page < this.pageCount) {
                this.page++;
            }
        },

        onSearch(searchValue = '') {
            this.searchQuery = searchValue
            this.page = 0
        },

        onOpenList() {
            if(this.page === 0){
                this.page = 1;
            }
        },

        onInputParentSheet(value) {
            this.parentSheet = value;
        },

        onCopy() {
            this.$emit("onCopyTemplateModal", {
                parent_setting_id: this.selectedId,
                parent_sheet_index: this.parentSheet ? (this.parentSheet - 1) : null
            });
        },

        getPage() {
            ServiceCloudImport.list(this.page, this.searchParams, (result) => {
                const {pageCount = 0, items = [], columns = []} = result;
                const itemsMapped = items.map((item) => {
                    const {id, name: value} = item;
                    return {id, value}
                })

                if(this.page === 1){
                    this.templateList = [...itemsMapped];
                }else{
                    this.templateList = [...this.templateList, ...itemsMapped];
                }

                this.columns = columns;
                this.pageCount = pageCount;
            }, () => {});
        },

        resetList() {
            this.page = 1;
            this.templateList = [];
        }
    },
};
</script>

<style lang="scss" scoped>

.sheet-input {
    width: 70px;
}

.template{
    position: relative;
    background: white;
    border-radius: 10px;

    &__content-options {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;
    }

    &__content-options > div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__content-options h3 {
        margin-bottom: 4px;
        text-align: center;
    }

    &__title{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
        width: 100%;
    }

    &__text {
        font-size: 12px;
        margin-bottom: 5px;
    }

    &__content{
        width: 100%;
        display: flex;
        padding: 0;
        margin-bottom: 12px;
    }

    &__button-container{
        width: 100%;
        display: flex;

        &__right{
            justify-content: right;
            display: flex;
        }
    }

}
</style>
